<template>
  <div class="container PaymentsAgriment">

     <h2>Условия оплаты</h2>
     
      <h4>Правила оплаты</h4>
      <p>К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, МИР.
        <br>Для оплаты банковской картой при оформлении заказа в телеграм-боте выберите способ оплаты: банковской картой.
        <br>При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:
        <br>1. Тип карты
        <br>2. Номер карты,
        <br>3. Срок действия карты (указан на лицевой стороне карты)
        <br>4. Имя держателя карты (латинскими буквами, точно также как указано на карте)
        <br>5. CVC2/CVV2 код
      </p>

      <p>Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>

      <p>Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии шифрования SSL. Эта информация недоступна посторонним лицам.
        <br>Советы и рекомендации по необходимым мерам безопасности проведения платежей с использованием банковской карты:
        <br>1. Берегите свои пластиковые карты так же, как бережете наличные деньги. Не забывайте их в машине, ресторане, магазине и т.д.
        <br>2. Никогда не передавайте полный номер своей кредитной карты по телефону каким-либо лицам или компаниям
        <br>3. Всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с банком
        <br>4. Вводите реквизиты карты только при совершении покупки. Никогда не указывайте их по каким-то другим причинам.</p>
      
      <h4>Отказ и возврат</h4>
      <p>Право потребителя на расторжение договора регламентируется законом «О защите прав потребителей»      
      <br>Потребитель вправе запросить возврат оплаченых денежных средств до момента использования лицензии (в соответствии с <b-link :to="{name: 'PablicOffer'}">Лицензионным соглашением</b-link> )
      <br>Зарос на возврат осуществляется в интерфейсе телеграм-бота.  
      <br>Возврат денежных средств потребителю осуществляется в течении 5 рабочих дней.
      <br>В случае осущетсвления возврата - покупателю возвращается полная сумма конкретного запроса на возврат.</p>

  </div>
</template>

<script>
  export default {
    name: "PaymentsAgriment",
  };
</script>

<style lang="scss" scoped>
.PaymentsAgriment{
  padding-top: 10%;
}
</style>
